import React from "react";
import loadable from "@loadable/component";
import { Description, LayoutWrapper } from "../../../../styles/CommonStyles";
import { StorySectionWrapper, TextContainer } from "./StorySection.style";

const PageHeading = loadable(() => import("@common/PageHeading/PageHeading"));

const StorySection = () => (
    <LayoutWrapper>
        <StorySectionWrapper>
            <PageHeading heading="Our story" />
            <TextContainer>
                <Description>
                    The Impressit journey started in early 2018 to help clients
                    build world-class digital products with outstanding user
                    experiences. This is possible due to our team of analytical
                    thinkers, talented designers and experienced engineers who
                    are committed to only delivering outstanding results and
                    exceeding expectations. We avoid the ordinary and strive for
                    the exceptional.
                </Description>
                <Description>
                    &ldquo;I&apos;m impressed!&rdquo; - was the feedback of our
                    first client when our co-founders Roman Zomko and Andrew
                    Lekh demoed the finished product. That&apos;s how the name
                    Impressit was born and since then our mission is to hear the
                    same feedback from every single individual working with
                    Impressit.
                </Description>
            </TextContainer>
        </StorySectionWrapper>
    </LayoutWrapper>
);

export default StorySection;
