import styled from "styled-components";
import { TABLET_MAX_WIDTH } from "@constants";

export const StorySectionWrapper = styled.div`
    padding: 5rem 0;

    & p:first-child {
        margin-right: 40px;
    }

    & .page-heading__heading {
        margin-bottom: 40px;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        padding: 2.5rem 0;

        & p:first-child {
            margin: 0 0 24px;
        }
        & .page-heading__heading {
            margin-bottom: 24px;
        }
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    & p {
        width: calc(50% - 20px);
        margin: 0;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        & p {
            width: 100%;
        }
    }
`;
